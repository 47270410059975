import React from "react";

const Card = ({ quote }) => {
  return (
    <div className="card flex flex-col items-center justify-center px-5 py-8 max-w-[650px] h-64 overflow-y-auto">
      <p className="text-2xl font-mono font-bold text-center">{quote}</p>
    </div>
  );
};

export default Card;
