import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Qcom from "./components/Qcom"
import NotFound from "./components/NotFound"

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/qcom' element={<Qcom />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);
reportWebVitals();
