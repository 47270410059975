export const quotes = [
  "love when he poro's makes me want to take him unto my bosom and rear him like a mama labrador does her baby pups",
  "wonder if lil pup ever turns to the invisible camera out in the world and says \"mods?!\" with that boyish twinkle in his eye",
  "can't lay in bed for more than two minutes without absolutely clobbering my albino toblerone to the warm rose-tinted memories of adolescence",
  "wish my bestie would get his nut up already and shuck my clam like it was Shellfish Sunday at Bubba Gump Shrimp",
  "once i gave a homeless man some tacos, he frowned at me and asked if i had any hot sauce so i bent over a gave him a bottoms-up gleek",
  "haven't eaten for like 8 hours my mouth is starting to taste like bull spunk",
  "bro sounds like someone stuck a vibrator up the heinie of an oompa loompa",
  "guy actually sounds like he's on a bang bus getting clowned on by five guys",
  "guy sounds like his brain got battered by kandy kong's gorilla pussy",
  "really it's time to evacuate my loins into a heated cum canvas while thinking of every beautiful memory on rewind like the final few seconds of consciousness",
  "play with penis like a double-tiered electric organ = practice",
  "do you think it's the chungus that crawled up your keister and is working your levers like an ancient daemon?",
  "pretty sure i could power a city-state with my taint. thing's buzzing out heat like a high-end luxury car radiator",
  "that ASMR just made me shoot so far into the air. it came back down, nailed me in the cornea and i think i got pink eye",
  "i just a grew a fully-engorged throbulating goose dick",
  "sometimes i fall asleep on my apartment floor with my ear cupped to the carpet waiting to catch sounds of intimacy from the downstairs neighbors",
  "honestly findom sounds kinda fun. when i think of all the places a penny has been i wanna pop it between my cheeks like a gobstopper",
  "after i pass i hope someone will scrape my crusted seed from my bedroom and in vitro fertilize an actualized descendent to further my blood line and make my momma proud",
  "anyone else trying to buckle peach's royal minge to their craw like a horse's feeding bag and feast for the rest of eternity",
  "sea legs are when you get bollocked so hard you start walking stilted like an 18th century whaler leaving the port of Hull after a week of drinking",
  "if i wanted to see a camel i'd just lift my aunt's tail man",
  "the only arithmetic this guy has done in the last 10 years is counting his pennies in the piggyback for buying that fully-articulating princess toadstool sex doll",
  "just found out my family has the 'sweet pussy' gene that tastes like butterscotch cookies and highland whisky",
  "really trying to strap peach's royal minge to my craw like a horse's feeding bag and feast for the rest of eternity",
  "do you really need a doctor to tell you should spend less than 4 hours inside your tifa sex doll per day?",
  "tell her you can buy her gifts with all the money you'll save from fast food by strapping her nethers to your cranium like a horse's feeding bag",
  "tell her you'll get on all fours and undress her tiny ass skirt with your unbrushed teeth",
  "ask this one if you can dismantle her back like Manny Machado breaking in a tight new glove just off the shelf from sports authority",
  "not sure i can handle another baddie i just filled my boxer briefs with a million unborn little babies",
  "this siren sounds like my morality and guilt kicking in after clobbering my imported sex doll into an unrecognizable crumpled mess",
  "why does it sound like he's getting a deep tonsillectomy from dry bowser's bone dry lizard tail when he coughs",
  "the boo snickering reminds me of the zoo toucans goading me into trying my beautiful sister's prosciutto during my first wet dream",
  "do you think shiny breasts shoot water out, like how some rare privileged few like h2o in their breakfast cereal?",
  "i'd give up my blood line to drive mips like a loose barnyard wheelbarrow in my fruit of the looms",
  "maybe not jack off at others' expense to keeping arcane mario tricks in your little kitty cat degen club",
  "4 hours after macca's and my tongue has settled into a latent bile like i went down on ronald mcdonald's calcified cracker jack pussy",
  "just plumbed him for his toys like you do soccer moms for breast milk",
  "would you also consider it science when i psychoanalyze my seed bursts on the laptop at the end of each week, looking for freudian patterns in the ecstasy emissions?",
  "poor little millionaire sucking on the all-natural dropping teat of Big Rent with a milk-sick smile",
  "i feel like he pokes my gonads with a voodoo doll when he says 'poop' like that",
  "make a mental note, unkink that little garden snake, hike up your long johns and kick the next run in the keister",
  "you ever think of spreading honey on your gooch beef to attract the fly, then keep it alive in your nectar as a warning to the other house pests?",
  "bro's looking into Mita's eyes, but all he sees are his best friend's torsioned bollocks",
  "had carne asada with a nice spicy red sauce 6 hours ago and just now it's starting to taste like i tongue punched a komodo dragon's desert dry taint",
  "why do you say speedrun like you're wearing walmart aviators and have a broomstick hiked up your fanny",
  "anyone else so dead inside they feel each ounce of their skeleton's weight seated in their sloppy frame, unable to even drag themselves to the bathroom and spray a feeble old-man piss all over their feet to get their rocks off",
  "honestly why the fuck did you ask about avengers? if i wanted to see dog dick i'd pull back my aunt's tail",
  "i'd rather watch my parents defile themselves out of wedlock than watch that putrid ass shit",
  "that just chilled my tongue like a fresh blue icee. helped cool down my beautiful sister's busy little beaver",
  "ever wish there was another anatomical flavor to fill your diaper with? like i've done mayo, ketchup, mustard, and chipotle, but where's the tomatillo verde sauce you know?",
  "if i bordered your unit i'd have a permanent glass pressed up against our shared wall while my other hand grooves on my grundle like a Keith Emerson solo",
  "if i had a bod like him i'd never leave the house. i'd be playing with myself like i was a michelin star charcuterie board",
  "you ever draw your best friend's face on your naughty towel with an expo marker to feel what it'd be like to burst through the platonic barrier?",
  "i'd auction off my brother for even just a bite-sized crumble of that boutique deodorant",
  "fuck if i don't want to pinch his tastykake cheeks when he's bleeting out noooo like a mewing little kitten",
  "dude we can see your manhood poking out like a little submarine periscope",
  "yeah but would he have to devour wet ass peaches like some sort of perverted exhibitionist mukbang fetish?",
  "you ever get so much crystallized salt under your nails from fast food french fries that the neighborhood cats line up to lick your fingies one by one like a cute little boy band"
]
