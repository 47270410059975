import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

export default function NotFound() {
    return (
        <div className="card h-screen flex justify-center items-center">
          <Helmet>
            <title>hey</title>
          </Helmet>
          <nav>
          <ul>
            <p className="font-bold">404 ur probably lost</p>
            <li className="underline"><Link to='/'>home</Link></li>
            <li className="underline"><Link to='/qcom'>qcom quoter</Link></li>
          </ul>
          </nav>
        </div>
    )
}
