import '../App.css';
import Card from './Card';
import { quotes } from '../constants/quotes.js'
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

function Qcom() {
  const [quote, setQuote] = useState();

  function getQuote() {
    const len = quotes.length;
    setQuote(quotes[Math.floor(Math.random() * len)]);
  }

  useEffect(() => {
    getQuote()
  }, [])

  const pageTitle = "qcom0 quoter";

  return (
    <div className="App h-screen">
      <Helmet>
        <title>the legendary qcom0</title>
      </Helmet>
      <div className="card px-5 py-8">
        <p className="text-2xl font-mono font-bold text-center">{pageTitle}</p>
      </div>
      <div className="flex justify-center items-center">
        <div className="px-5 py-64">
          <div className="max-h-64 overflow-y-auto">
            {quote && <Card quote={quote} />}
          </div>
          <button
            onClick={getQuote}
            className="bg-purple-400 px-5 py-4 mt-5 text-white font-bold ml-64"
          >
            Get Quote
          </button>
        </div>
      </div>
    </div>
  );
}

export default Qcom;
