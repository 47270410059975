import './App.css';
import { Outlet, Link } from "react-router-dom";
import {Helmet} from "react-helmet";

function App() {
  return (
    <div className="card h-screen flex justify-center items-center">
      <Helmet>
        <title>hey</title>
      </Helmet>
      <nav>
        <ul>
          <li className="underline">
            <Link to="/qcom">qcom quoter</Link>
          </li>
        </ul>
      </nav>

      <Outlet />
    </div>
  )
};

export default App;
